import {Document, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import KarimSignature from "../../assets/karim.jpg";
import KarimLogo from "../../assets/logo-karim.png";
import jwtDecode from "jwt-decode";

interface Props {
  invoiceDetails: any;
  logo?: string;
}

const styles
  = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginHorizontal: 'auto',
    marginTop: 20,
    border: '5px solid gray',
    paddingVertical: 5
  },
  textNormal: {
    paddingVertical: 3,
    fontSize: 10,
    fontWeight: "thin",
    fontFamily: "Roboto"
  },
  textNormalLargePadding: {
    paddingVertical: 3,
    fontSize: 10,
    fontWeight: 'thin',
    fontFamily: 'Roboto'
  },
  textNormalNoPadding: {
    fontSize: 10,
    fontWeight: "thin",
    fontFamily: "Roboto"
  },
  textBoldNoPadding: {
    fontSize: 10,
    fontWeight: 800,
    fontFamily: 'Roboto-Bold',
  },
  borderTextBold: {
    fontSize: 10,
    fontWeight: 800,
    fontFamily: 'Roboto-Bold',
    paddingVertical: 1
  },
  textImportant: {
    fontSize: 15,
    fontWeight: 'bold',
    fontFamily: 'Roboto-Bold'
  },
  borderColumn: {
    width: '40%', border: '0.5px solid black'
  },
  borderValue: {
    width: '60%',
    border: '0.5px solid black'
  },
  logo: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', width: '27%'
  },
  flex: {
    display: 'flex'
  },
  signature: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '15%',
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10
  },
  borderText: {
    width: '90%', border: '1px solid black', display: 'flex', flexDirection: 'row'
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10
  },
  titleText: {
    fontFamily: 'Times-Bold',
    fontWeight: 'bold',
    fontSize: 20
  },
  clientInformation: {
    flexDirection: 'column',
    width: '90%',
    marginTop: 5,
    marginBottom: 20,
    marginHorizontal: 'auto'
  },
  pageContainer: {
    maxHeight: '100%',
    flexDirection: 'column',
    width: '90%',
    paddingTop: 20,
    paddingBottom: 30
  },
  centered: {},
  truckDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    marginBottom: 10,
    marginHorizontal: 'auto',

  },
  importantText: {width: '90%', marginTop: 5, marginLeft: 10, flexGrow: 1},
  divider: {width: '100%', marginTop: 20, marginBottom: 20},
  transportInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    marginLeft: 30,
    marginRight: 10,
    marginBottom: 10
  },
});

const TmsClientTemplate = (props: Props) => {
  const currentUser: any = jwtDecode(localStorage.getItem('token')!);
  const dateDiff = (date_from: string, date_to: string) => {
    const parsedFrom = new Date(date_from).setHours(0, 0, 0, 0);
    const parsedTo = new Date(date_to).setHours(0, 0, 0, 0);

    return parsedFrom.valueOf() !== parsedTo.valueOf();
  }

  return (
    <Document style={{display: 'none'}}>
      <Page size='A4' wrap style={styles.pageContainer}>

        <View style={styles.header}>

          <Image style={{width: '30%', height: '100%', margin: 'auto'}} src={KarimLogo}/>

          <View style={{marginLeft: 10, width: '50%'}}>
            <Text style={styles.textBoldNoPadding}>Karim Spedition Logistik S.R.L.</Text>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>CUI:RO 42581537, J5/845/2020</Text>
            </View>
            <Text style={styles.textBoldNoPadding}>Strada Sovata 35, PB15, ap.4, Oradea, Bihor,
              Romania</Text>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>Mobil:+40757574981 / +40751325311 </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>e-mail: office@karim-spedition.com</Text>
            </View>
          </View>

        </View>

        <View style={styles.title}>
          <Text style={styles.titleText}>Comanda de
            transport {props.invoiceDetails?.series}-{props.invoiceDetails?.number} din {new Date(props.invoiceDetails?.created_at_utc).toLocaleDateString('ro-RO')}</Text>
        </View>

        <View style={styles.clientInformation}>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>CLIENT:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.buyer?.name}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>CUI :</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.buyer?.unique_identification_number}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>ADRESA:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.buyer?.address}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>PERSOANA DE CONTACT:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {`${props?.invoiceDetails?.client_contact || '-'}`}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.transportInfo}>
          <Text style={{
            fontFamily: 'Roboto',
            fontSize: 12
          }}>DETALII CAMION / MARFA</Text>

          <Text style={styles.textBoldNoPadding}>Marfa : {props.invoiceDetails?.package_info}</Text>
          <Text style={styles.textBoldNoPadding}>Nr. auto
            : {props?.invoiceDetails?.car_reg_number || '-'}</Text>
          <Text style={styles.textBoldNoPadding}>Sofer / Tel.
            Sofer: {props?.invoiceDetails?.driver_info || '-'}</Text>
          <Text
            style={styles.textBoldNoPadding}>Observatii: {props?.invoiceDetails?.remarks_buyer}</Text>
        </View>

        {props?.invoiceDetails?.OrderDetails?.map((orderDetailsItem: any) => (
          <>
            {orderDetailsItem.type === 'PICKUP' &&
                <View style={styles.truckDetails} wrap={false}>
                    <Text style={{
                      fontFamily: 'Roboto',
                      fontSize: 16,
                      marginLeft: 30
                    }}>Incarcare</Text>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Data
                                / Ora
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {`${new Date(orderDetailsItem.date_from).toLocaleDateString('RO-ro')}${dateDiff(orderDetailsItem.date_from, orderDetailsItem.date_to) ? `/ ${new Date(orderDetailsItem.date_to).toLocaleDateString('RO-ro')}` : ''} `}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Firma :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.company}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Adresa :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.address}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Oras/Cod/Tara :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.location}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Observatii:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem.remarks || '-'}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Referinta:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.reference || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            }

            {orderDetailsItem.type === 'DROPOFF' &&
                <View style={styles.truckDetails} wrap={false}>
                    <Text style={{
                      fontFamily: 'Roboto',
                      fontSize: 16,
                      marginLeft: 30
                    }}>Descarcare</Text>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Data / Ora :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {`${new Date(orderDetailsItem.date_from).toLocaleDateString('RO-ro')}${dateDiff(orderDetailsItem.date_from, orderDetailsItem.date_to) ? `/ ${new Date(orderDetailsItem.date_to).toLocaleDateString('RO-ro')}` : ''} `}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Firma :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.company}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Adresa :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.address}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Oras/Cod/Tara :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.location}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Observatii:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem.remarks}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Referinta:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.reference || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            }
          </>
        ))}

        <View wrap={false} break={props?.invoiceDetails?.OrderDetails.length > 2}
              style={{marginTop: 30, marginBottom: 10}}>

          <Text style={{fontSize: 16, fontWeight: 'bold', marginLeft: 30}}>Pret
            convenit: {parseFloat((Number(props.invoiceDetails?.client_price).toFixed(2)))}
            {' ' + props.invoiceDetails.client_currency}
            {props.invoiceDetails.client_vat > 0 ? ' + TVA' : ''}</Text>
          <Text style={{fontWeight: 'bold', fontSize: 12, marginLeft: 30, marginTop: 20}}>
            Tarif negociat si
            acceptat {props.invoiceDetails.client_vat > 0 ? '(nu include TVA)' : ''}:
            {parseFloat((Number(props.invoiceDetails?.client_price).toFixed(2)))}
            {' ' + props.invoiceDetails.client_currency}
          </Text>

        </View>

        <View style={{height: '80%'}} wrap break={props?.invoiceDetails?.OrderDetails?.length < 3}>
          <Text style={{fontWeight: 'bold', fontSize: 14, marginLeft: 30, marginRight: 10}}> CONDITII PLATA: virament
            bancar
            la {props.invoiceDetails?.buyer?.invoice_deadline_days} Zile de la
            facturare </Text>

          <View wrap style={{marginLeft: 30, marginRight: 10}}>
            <Text style={styles.textNormalLargePadding}>1. Prezenta reprezinta contract ferm, iar Anexa este parte
              integranta a
              prezentului contract. Contractul este
              confidential iar continutul sau nu va fi facut cunoscut tertilor.</Text>

            <Text style={styles.textNormalLargePadding}>2. Temeiul juridic al prezentului contract este reprezentat de
              Codul Civil
              (art.
              2064 si urm.) precum si
              Conditiile Generale care reglementeaza operatiunile efectuate de Casele de Expeditii - Uniunea
              Societatilor
              de
              Expeditii din Romania, cu respectarea Regulamentului UE 2016/679.</Text>
            <Text style={styles.textNormalLargePadding}>3. Orice modificari in derularea si executarea prezentului
              contract se
              anunta
              in
              scris si intra in vigoare prin
              acordul ambelor parti.</Text>
            <Text style={styles.textNormalLargePadding}>4. In cazul in care Beneficiarul nu respecta datele de
              incarcare/descarcare
              sau
              renunta la comanda dupa
              confirmare, va fi penalizat cu suma de 150 EUR/zi intarziere inceputa, respectiv 500 EUR pentru anularea
              comenzii. Prin renuntare la comanda se intelege si o intarziere la incarcare mai mare de 2
              zile.</Text>
            <Text style={styles.textNormalLargePadding}>5. Camionul care merge la incarcat se afla in stare tehnica si
              functionala
              buna,
              asigurata integral de
              transportator.</Text>
            <Text style={styles.textNormalLargePadding}>6. Transporturile se organizeaza cu furnizorii nostri in baza
              Conventiei
              CMR,
              cu
              o
              valoarea a
              asigurarii CMR conform indicatiilor speciale. Cererile de despagubire vor fi instrumentate si solutionate
              de
              societatile de asigurare competente, iar Beneficiarul se obliga sa puna la dispozitie in cel mai scurt
              timp
              toate documentele solicitate de acestea.</Text>
            <Text style={styles.textNormalLargePadding}>7. Timp liber acordat transportatorului la incarcare/descarcare
              24
              ore.</Text>

            <Text style={styles.textNormalLargePadding}>8. Efectuarea tuturor formalitatilor vamale si descarcarea
              marfii trebuie sa
              se
              efectueze in termen de maxim 24
              ore de la sosirea camionului, altfel pentru fiecare zi ulterioara termenului sus mentionat se calculeaza
              timp de
              stationare in valoare de 250 EUR/zi inceputa.</Text>

            <Text style={styles.textNormalLargePadding}>9. Nerespectarea termenelor de plata se penalizeaza cu 1% pe zi
              de
              intarziere
              pentru primele 10 zile, iar dupa
              aceasta a 10-a zi penalizarile de intarziere se majoreaza la 5% pe zi de intarziere. Penalizarile pot
              depasi
              suma datorata. Pentru toate pretentiile proprii ce izvorasc din prezentul contract, KARIM are dreptul de
              a le
              compensa cu pretentiile Beneficiarului.</Text>

            <Text style={styles.textNormalLargePadding}>10. Beneficiarului ii revine obligatia de a se asigura ca
              transmite corect
              furnizorului datele privind
              identificarea transportatorului, a mijlocului de transport si de asemenea are obligatia de a se asigura ca
              furnizorul (incarcatorul) marfii completeaza corect si complet scrisoarea de trasura si ca il identifica
              pe
              transportatorul, mijlocul de transport si respectiv pe conducatorul mijlocului de transport caruia ii
              preda
              (incarca) marfa, retinand in acest sens si copii ale documentelor doveditoare (certificat identificare
              transportator, copie act identitate conducator auto, copie acte mijloc de transport, etc). In cazul
              incalcarii
              acestor obligatii de catre beneficiar si/sau respectiv de catre furnizor (incarcator),KARIM este
              exonerata de orice raspundere pentru predarea marfii unei persoane fara drept de a o prelua, pierderea,
              furtul sau
              confiscare/sechestrarea acesteia, intarziere in transport sau orice alte daune aferente.</Text>

            <Text style={styles.textNormalLargePadding}>11. Clauza penala: contactarea directa a transportatorului de
              catre
              Beneficiar
              fara acordul scris al subscrisei
              atrage impunerea unei penalizari de 10.000 EUR.</Text>

            <Text style={styles.textNormalLargePadding}>12. Toate litigiile aparute in derularea si executarea
              prezentului contract
              se
              vor solutiona pe cale amiabila,
              dar daca nu este posibil litigiile vor fi solutionate de catre instantele competente din
              Bucuresti.</Text>

            <Text style={styles.textNormalLargePadding}>13. Obiectul contractului si durata sa este reprezentat de
              transportul de
              marfa
              cu specificatiile de mai sus.</Text>

            <Text style={styles.textNormalLargePadding}>14. Neexprimarea letrica in termen de 1 (una) ora de la primirea
              contractului
              a
              pozitiei Dvs. se considera
              acceptarea tacita a acestuia.</Text>

            <Text style={styles.textNormalLargePadding}>15. Contractul intra in vigoare astazi, data semnarii sale si
              expira la data
              achitarii obligatiilor scadente,
              calculate conform contractului.</Text>
          </View>
        </View>

        <View style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginLeft: 30,
          marginRight: 30,
          paddingBottom: 20
        }}>
          <View style={{display: 'flex', flexDirection: 'column',}}>
            <Text style={{fontSize: 12, fontWeight: "thin"}}>Cu stima,</Text>
            <Text style={{fontSize: 12, fontWeight: "thin"}}>{currentUser?.name}</Text>
            <View style={{width: '30%', height: '32%'}}></View>
          </View>

          <View style={{display: 'flex', flexDirection: 'column'}}>
            <Text style={{fontSize: 12, fontWeight: "thin"}}>Confirmare Client:</Text>
            <Text style={{fontSize: 12, fontWeight: "thin"}}>Semnatura si stampila</Text>
            <View style={{width: '30%', height: '32%'}}></View>

          </View>
        </View>

        <Image fixed style={{position: 'absolute', bottom: 30, marginLeft: 30, width: '14%', height: '10%'}}
               src={KarimSignature}/>
      </Page>
    </Document>
  )
}

export default TmsClientTemplate;