export const RepairEstimateTableConstants = [
    {
      id: 'deviz_id',
      label: 'Deviz',
      width: 100,
      type: 'href'
    },
    {
      id: 'name',
      label: 'Firma / Vehicul',
      width: 100,
    },
    {
      id: 'date',
      type: 'date',
      label: 'Data',
      width: 100,
    },
    {
      id: 'denumire',
      label: 'Denumire',
      width: 150
    },
    {
      id: 'pret_fara_tva',
      label: 'Pret fara TVA',
      width: 100,
    },
    {
      id: 'cota_tva',
      label: 'Cota TVA',
      width: 100,
    },
    {
      id: "infos",
      label: "Observatii",
      width: 100,
    }
  ]